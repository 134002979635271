#toggle_nav:checked ~ div #hamburger #line {
    @apply translate-y-1.5 rotate-45;
}

#toggle_nav:checked ~ div #hamburger #line2 {
    @apply -translate-y-1 -rotate-45;
}

.toggled div:first-child {
    @apply translate-y-1.5 rotate-45;
}
.toggled div:last-child {
    @apply -translate-y-1 -rotate-45;
}